@keyframes easetext {
  from {
    opacity: 0.3;
    filter: blur(5px);
  }

  to {
    opacity: 1;
    filter: blur(0);
  }
}

.main-body-animation {
  animation: 1s easetext;
}

.auth-input::placeholder {
  color: white;
}

.phone-number-input::placeholder {
  color: white;
}
